
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMedia } from '../../MediaProvider';

function Bookfooter({ imageUrl }) {
    const { t } = useTranslation();
     const { media } = useMedia();
            const Icons = media.Icons
    return (
        <div className='relative  flex justify-center items-center h-490' >
            <img loading="lazy" src={imageUrl ? imageUrl : Icons.Bookyourstay} className='w-full h-full absolute top-0 left-0 object-cover' style={{ filter: "brightness(0.5)",objectPosition: "left top"}} />
            <div className='relative text-center container'>
              <Link to="https://reservations.fiddlerlakeresort.com/">  <h3 className="Alfarn text-white text-64 mb-6  max-[786px]:mb-3 capitalize" style={{ lineHeight: "47px" }}>{t("Book your stay")}</h3></Link>
                <h3 className="AkagiPro-Black text-white text-20 " style={{ lineHeight: "25px" }}>{t("FOLLOW OUR SOCIAL MEDIA TO KEEP UP WITH THE LATEST PROMOTIONS")}</h3>
                <div className='max-[786px]:gap-1 md:gap-8   flex justify-center items-baseline  md:mt-8 max-[786px]:mt-4  '>
                    <Link to="https://www.facebook.com/FiddlerLake/">
                        <img loading="lazy" src={Icons.Facebook} className='w-8  max-[786px]:w-6' />
                    </Link>
                    <Link to="https://www.instagram.com/fiddler_lake_resort/">
                        <img loading="lazy" src={Icons.instagram} className='w-8  max-[786px]:w-6' />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Bookfooter;
