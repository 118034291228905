import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState, useRef } from "react";
import BookNow from "../Button/BookNow";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setRedirect } from "../../Config/Actions/redirectchalets";
import { useMedia } from "../../MediaProvider";

export default function Chalersslider({ handleRedirect }) {
  const { media, changeMedia, mediaPaths } = useMedia();
  const Icons = media.Icons;
  const dispatch = useDispatch();
  const [slidesToShow, setSlidesToShow] = useState(2);
  const [slidesTorows, setSlidesTorows] = useState(1);
  const [sliderReady, setSliderReady] = useState(true);
  const sliderRef = useRef(null);

  const beardata = [
    {
      img: Icons.Rabbit,
      name: "rabbit",
      title:
        "These bright and comfortable chalets are perfect for retreats with friends or family. Create endless memories in this charming chalet.",
      Vectors: Icons.Vectorcagaru,
      lanermor: "/discoverchalets",
      id: "1",
      while: "50px",
    },
    {
      img: Icons.Bear,
      name: "bear",
      title:
        "This model caters to guests looking to combine luxury and adventure. With its majestic windows, our Bear chalet offers breathtaking views of nature.",
      Vectors: Icons.Vectorrish,
      id: "2",
      lanermor: "/discoverchalets",
      while: "70px",
    },
    {
      img: Icons.Deer,
      name: "deer",
      title:
        "Indulge in a spacious, two-storey chalet with plenty of room for family, friends, or colleagues. This elegant model has cathedral ceilings and panoramic windows, and all the extras to make your stay unforgettable.",
      Vectors: Icons.Vector8,
      id: "3",
      lanermor: "/discoverchalets",
    },
    {
      img: Icons.Moose,
      name: "moose",
      title:
        "These larger chalets are the perfect retreat for corporate retreats and larger groups. Our Moose chalet is sure to amaze you with its majestic and impressive structure.",
      Vectors: Icons.Vector9,
      id: "4",
      lanermor: "/discoverchalets",
      while: "60px",
    },

    {
      img: Icons.Elk,
      name: "elk",
      title:
        "Come experience the splendour of nature in this exclusive chalet. This beautifully appointed model provides luxury and plenty of space so you can host a corporate event or accommodate a large family.",
      Vectors: Icons.Vector10,
      id: "5",
      lanermor: "/discoverchalets",
    },
  ];

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setSlidesToShow(1);
        setSlidesTorows(1);
      } else if (window.innerWidth >= 768 && window.innerWidth < 1280) {
        setSlidesToShow(3);
        setSlidesTorows(1);
      } else {
        setSlidesToShow(3);
        setSlidesTorows(1);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      setSliderReady(true);
    }
  }, [sliderRef]);

  const settings = {
    dots: false,
    rows: slidesTorows,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: (
      <button
        className="slick-prev"
        onClick={() => sliderRef.current?.slickPrev()}
      >
        Previous
      </button>
    ),
    nextArrow: (
      <button
        className="slick-next"
        onClick={() => sliderRef.current?.slickNext()}
      >
        Next
      </button>
    ),
  };

  const handleLinkClick = (item) => {
    handleRedirect(item);
    dispatch(setRedirect(item.id));
  };

  const { t, i18n } = useTranslation();
  const currentLanguageCode = i18n.language;
  const urls = {
    en: "https://reservations.fiddlerlakeresort.com/",
    fr: "https://reservations.fiddlerlakeresort.com/fr/",
  };
  const currentUrl = urls[currentLanguageCode] || urls.en;

  return (
    <>
      <Slider {...settings} className="slider_left">
        {beardata.map((item, index) => (
          <div key={index.toString()}>
            <div className="rabbitslider  relative">
              <div className="block  md:gap-5  max-[767px]:gap-4 items-center">
                <img
                  loading="lazy"
                  src={item.img}
                  style={{ overflowClipMargin: "unset" }}
                  className=" w-full  h-full"
                />
                <div style={{ marginTop: "0px" }} className="p-4">
                  {BookNow.map((BookNow, index) => (
                    <Link key={index.toString()} to={currentUrl}>
                      <button className="md:w-52 w-20 AkagiPro-Black book_button absolute  top-4 md:-right-4 max-[786px]:-right-1 uppercase text-20 book_now">
                        {t("book now").slice(0, 8)}
                      </button>
                    </Link>
                  ))}
                  <h2 className="text-48 Alfarn flex grid-cols-2 items-baseline gap-4 capitalize">
                    {t(item.name)}{" "}
                    <img
                      className=" max-[767px]:w-5 md:w-12 unocon_r "
                      loading="lazy"
                      style={{ width: `${item.while}` }}
                      src={item.Vectors}
                    />
                  </h2>
                  <p className="text-20 AkagiPro-Medium text-ellips md:mt-4 collapse_txte">
                    {t(item.title)}{" "}
                  </p>

                  {BookNow.map((BookNow, index) => (
                    <Link
                      key={index.toString()}
                      to={item.lanermor}
                      onClick={() => handleLinkClick(item)}
                    >
                      {" "}
                      <h3 className="AkagiPro-Black text-20 mt-2 text-lightyellow flex grid-cols-2 items-center uppercase  gap-1">
                        {t("Learn more")}{" "}
                        <img
                          src={Icons.Tira}
                          loading="lazy"
                          className="max-[767px]:w-6"
                        />
                      </h3>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {sliderReady.current && (
        <div className="absolute">
          <button onClick={() => sliderRef.current.slickPrev()}>
            Previous
          </button>
          <button onClick={() => sliderRef.current.slickNext()}>Next</button>
        </div>
      )}
    </>
  );
}
