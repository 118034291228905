import { Link } from 'react-router-dom';
import React from "react";
import BookNow from "../Button/BookNow";
import { useTranslation } from 'react-i18next';

function BackgroundImage({ Sauveur }) {
    const { t } = useTranslation();

    return (
        <div>
            {Sauveur.map((item, index) => (
                <div key={index.toString()} className="bg-no-repeat relative flex justify-center items-center text-white Depending_img" style={{
                    backgroundImage: `url(${item.bgimg})`,
                    backgroundPosition: 'center top',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    padding: "34px 1px",
                    minHeight: `${item.height}`,
                }} >
                    <div className='text-center' >
                    <h3 className="  Alfarn text-48 uppercase ">{t(item.name)}</h3>
                    <h3 className=" AkagiPro-Black text-30  uppercase" style={{ maxWidth: `${item.wirdth}`, margin: "auto", }}  dangerouslySetInnerHTML={{ __html: t(item.Rentals) }}  />  
            <p className=" AkagiPro-Black text-30  uppercase" style={{ maxWidth: `${item.wirdth}`, margin: "auto", padding: "5px 20px" }}>{t(item.montreal)}</p> 
                        <p className=" text-64 Alfarn" style={{ maxWidth: `${item.wridth}`, margin: "auto", padding: "5px 20px" }}>{t(item.montreald)}</p>
                        {BookNow.map((BookNow,index) => (<Link key={index.toString()} to={BookNow.Link}>
                            {item.button ? (
                                <button style={{ fontSize: "18px" }} className=' mt-12 mt_5  book_button button_p book_capital  AkagiPro-Black  '>
                                    {t(item.button)}
                                </button>
                            ) : (
                                <button style={{ display: "none" }}></button>
                            )}
                        </Link>))}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default BackgroundImage;
