import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import facebookd from "../../../Assets/Icons/facebookd.png";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
const TermsAndConditions = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const getLanguageFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("lang") || "en";
  };
  useEffect(() => {
    setIsLoading(true);
    const language = getLanguageFromURL();
    const changeLanguage = async () => {
      if (i18n.language !== language) {
        await i18n.changeLanguage(language);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    setTimeout(() => {
      changeLanguage();
    }, 500);
  }, [location.search, i18n]);
  const spinnerOverride = css`
    display: block;
    margin: 0 auto;
  `;
  return (
    <div className="bg-black text-white Terms_and">
      <header
        style={{ background: "#18140dff", borderBottom: "1px solid #e5cfad" }}
        className="py-3"
      >
        <div className="text-center container " style={{ maxWidth: "1124px" }}>
          <img
          loading="lazy"
            onClick={() => window.location.href="https://reservations.fiddlerlakeresort.com/"}
            style={{cursor:'pointer'}}
            className="object-scale-down w-[200px]"
            src="https://lh3.googleusercontent.com/Ll_HDPxH1aJx5ucPqE3vkqeg6EOTKKADdwtjbGV3-RxakFvA35_464Ay5YvdSX4_5ECFrO9Xqg_sRrZTacVwdAv2UjRQLaP4tPOP"
          />
        </div>
      </header>

      {!isLoading && (
        <div className="container" style={{ maxWidth: "1124px" }}>
          <div>
            <h1 className="text-center py-5 AkagiPro-Black text-white m-0">
              {t("Terms and Conditions")}
            </h1>
          </div>
          <p>{t("Prices")}</p>
          <p>
            {t(
              "All prices are in Canadian dollars (CAD) and are subject to applicable taxes and fees:"
            )}
          </p>
          <p>{t("Quebec accommodation tax: 3.5%/nightly price")}</p>
          <p>{t("Resort Fee: 5%")}</p>
          <p>{t("GST: 5%")}</p>
          <p>{t("QST: 9.975%")}</p>
          <p>{t("Arrival and departure times")}</p>
          <p>
            {t("Check-in can be done from 4:00 p.m. on the day of arrival.")}
          </p>
          <p>
            {t(
              "The chalet must be vacated before 11:00 a.m. on the day of your departure."
            )}
          </p>
          <p>{t("Reservation deposit")}</p>
          <p>
            {t(
              "A reservation deposit representing 50% of the total rental amount including applicable taxes will be required at the time of reservation. Once your deposit has been authorized, you will receive a confirmation letter as well as an invoice with your reference number. The balance of your bill will be taken from your credit card 5 days before your arrival."
            )}
          </p>
          <p>{t("Security deposit")}</p>
          <p>
            {t(
              "A security deposit of $1,000.00 for chalets without a private pool and $1,500.00 for chalets with a private pool will be pre-authorized on your credit card 3 days before your arrival. An inspection will be carried out by Fiddler Lake Resort staff before your arrival and after your departure. The security deposit will be released once it has been confirmed that the chalet has not been damaged."
            )}
          </p>
          <p>
            {t(
              "Charges for any damages in excess of the security deposit amount will be charged to the credit card used for the deposit."
            )}
          </p>
          <p>{t("Minimum age")}</p>
          <p>{t("The minimum age to reserve a chalet is 25 years old.")}</p>
          <p>{t("Number of sleepers")}</p>
          <p>
            {t(
              "The number of people sleeping in the chalet must not exceed the occupancy capacity of the chalet. Failure to comply with this rule will result in a penalty of $150.00 per additional person per night, which amount will be deducted from the security deposit, as well as termination of the rental contract."
            )}
          </p>
          <p>{t("Day visitors")}</p>
          <p>
            {t(
              "Tenants staying in the Lapin or Ours chalets are entitled to a maximum of 4 visitors per day, at no additional cost."
            )}
          </p>
          <p>
            {t(
              "Tenants staying in Les Cerf, Orignal and Wapiti are entitled to a maximum of 6 visitors per day, at no additional cost."
            )}
          </p>
          <p>
            {t(
              "Day visitors must leave the chalet no later than 11:00 p.m. Failure to comply with this rule will result in a fee of $500.00, which will be deducted from the security deposit."
            )}
          </p>
          <p>{t("Maintaining the chalet in good condition")}</p>
          <p>
            {t(
              "It is your responsibility to maintain the chalet in good condition during your stay and to return it to the condition it was in at the time of your arrival."
            )}
          </p>
          <p>
            {t(
              "The chalets are built with wooden floors for your comfort and enjoyment. In order to preserve them, all tenants are required to remove their shoes (shoes, boots, sandals, etc.) when entering the chalet. Shoes can be left at the entrance to the chalet."
            )}
          </p>
          <p>
            {t(
              "The chalet will be inspected after your departure. Any damage caused to the chalet will be deducted from the security deposit. If the condition of the chalet is deemed unsatisfactory, additional cleaning costs may be deducted from your security deposit."
            )}
          </p>
          <p>{t("Improper spa use")}</p>
          <p>
            {t(
              "Before your arrival, a photo of the spa water quality will be taken."
            )}
          </p>
          <p>
            {t(
              "In the event of inappropriate use of the spa, the following charges may apply:"
            )}
          </p>
          <p>
            {t(
              "- Verification requested, treatment necessary and/or addition of water required: Starting at $65.00"
            )}
          </p>
          <p>{t("- Water change required: Starting at $85.00")}</p>
          <p>
            {t(
              "- Closure of the spa and/or complete cleaning: Starting at $250.00"
            )}
          </p>
          <p>
            {t(
              "A routine spa check will be performed every other day. If you require a spa service call, please contact us before 11:00 a.m."
            )}
          </p>
          <p>
            {t(
              "The spa technician reserves the right to close and/or restrict use of the spa at any time if he or she determines that the spa can no longer be used safely due to improper use."
            )}
          </p>
          <p>
            {t(
              "Center de Villégiature Lac Fiddler cannot be held responsible for problems occurring after using the spa."
            )}
          </p>
          <p>{t("Pets")}</p>
          <p>
            {t(
              "Pets are not allowed in the chalets. A penalty of $250.00 per day will be deducted from the security deposit for failure to comply with this rule, in addition to any fees that may be deducted for any damage caused by pets."
            )}
          </p>
          <p>{t("Non-smokers")}</p>
          <p>
            {t(
              "Smoking is strictly prohibited inside the chalet. The chalet will be inspected after your departure. A penalty of up to $1,000.00 applies for failure to comply with this rule."
            )}
          </p>
          <p>{t("Open fires and fireworks")}</p>
          <p>
            {t(
              "For safety reasons, the town of Mille-Isles prohibits fireworks, firecrackers and open fires. Outdoor fires are only permitted in an outdoor fireplace reserved for this use. Failure to comply with this rule may result in a penalty of $500.00, which will be deducted from the security deposit."
            )}
          </p>
          <p>{t("Noise-related nuisances")}</p>
          <p>
            {t(
              "In accordance with the regulations concerning nuisances of the town of Mille-Isles, noise likely to disturb the peace, tranquility, comfort, rest, well-being of citizens or likely to prevent the peaceful use of the property in the neighborhood, constitutes a nuisance and is prohibited between 11 p.m. and 7 a.m. the next day. Failure to comply with this rule may result in a $1,000.00 penalty and risk of eviction from the Resort."
            )}
          </p>
          <p>{t("Recreational vehicles")}</p>
          <p>
            {t(
              "The use and installation of tents, trailers, caravans and other recreational vehicles is not permitted on the site."
            )}
          </p>
          <p>{t("Animals")}</p>
          <p>
            {t(
              "The Fiddler Lake Resort is located in the countryside so small animals such as mice could enter the chalet. Therefore, it is important to keep exterior doors closed at all times."
            )}
          </p>
          <p>{t("Responsibility")}</p>
          <p>
            {t(
              "Children must be accompanied by an adult at all times. Parents and guardians are entirely responsible for the safety of their children on site."
            )}
          </p>
          <p>
            {t(
              "The swimming pools and the lake are unsupervised. Rental of a chalet and use of facilities, equipment, and electrical appliances (including, among other things, use of the Internet, telephone, spa, sauna, barbecue) at the chalet and on the site in general are entirely at the tenants' risk."
            )}
          </p>
          <p>
            {t(
              "Center de Villégiature Lac Fiddler and the owners of the chalets will not be held responsible for any event causing death, injury, loss of personal items, or for any damage affecting the personal property of the tenant and/or his guests."
            )}
          </p>
          <p>{t("Force majeure")}</p>
          <p>
            {t(
              "In the event of an unforeseen situation (power outage, Internet interruption, lack of water, sewer problem, telephone interruption, etc.), no refund will be granted. Please note that our chalets do not have generators."
            )}
          </p>
          <p>
            {t(
              "In the event of an unforeseen event or natural disaster, Center de Villégiature Lac Fiddler will do its utmost to find an equivalent chalet on site. Fiddler Lake Resort will not be held responsible or assume any obligation whatsoever following events of this nature."
            )}
          </p>
          <p>{t("Personal information")}</p>
          <p>
            {t(
              "Your personal information will not be sold or disclosed to any third party for commercial purposes. Fiddler Lake Resort is committed to protecting and ensuring the security of all personal information. This information is stored securely and only a duly authorized user can access it. Your personal information is only kept for the period required to achieve the purposes for which it was collected."
            )}
          </p>
          <p>
            {t(
              "You have the right to refuse to receive promotional materials on the products and services of Center de Villégiature Lac Fiddler. However, if you wish, Fiddler Lake Resort can send you promotional materials."
            )}
          </p>
          <p>
            {t(
              "You may, upon request, have access to the personal information that Center de Villégiature Lac Fiddler holds about you. This information is stored and used in Canada and is therefore subject to Canadian laws."
            )}
          </p>
          <p>{t("Cancellation policy")}</p>
          <p>
            {t(
              "The cancellation conditions applicable to all reservations are as follows:"
            )}
          </p>
          <p>
            {t(
              "31 to 45 days before arrival: 50% refund of reservation deposit"
            )}
          </p>
          <p>{t("From 30 to 5 days before arrival: no refund")}</p>
          <p>
            {t(
              "From 5 to 1 day before arrival: payment of the total amount of the reservation, without any refund"
            )}
          </p>
          <p>
            {t(
              "In the event of cancellation, a 5% administrative fee will be deducted from any refund made, regardless of the number of days before the scheduled check-in date."
            )}
          </p>
          <p>{t("Acceptance")}</p>
          <p>
            {t(
              "I have read, understood and accepted all the conditions provided for in the rental contract which apply to the Lac Fiddler Resort."
            )}
          </p>
          <p className="">
            {t(
              "I AGREE THAT ANY DAMAGE CAUSED TO THE PROPERTY THAT EXCEEDS THE AMOUNT OF MY SECURITY DEPOSIT WILL BE CHARGED TO MY CREDIT CARD, UPON PRESENTATION OF PHOTOS OF THE DAMAGE."
            )}
          </p>
        </div>
      )}
      {!isLoading && (
        <footer style={{ background: "#18140dff" }} className="mt-5">
          <div style={{ borderBottom: "1px solid #e5cfad" }} className="py-5">
            <div
              className="flex container justify-between items-center py-3 max-[600px]:grid "
              style={{ maxWidth: "1124px" }}
            >
              <div className="text-center  ">
                <img
                loading="lazy"
                  className="object-scale-down w-[200px]"
                  src="https://lh3.googleusercontent.com/Ll_HDPxH1aJx5ucPqE3vkqeg6EOTKKADdwtjbGV3-RxakFvA35_464Ay5YvdSX4_5ECFrO9Xqg_sRrZTacVwdAv2UjRQLaP4tPOP"
                />
              </div>
              <p className=" ">{t("Terms and Conditions")}</p>
            </div>
          </div>
          <div>
            <div
              className="flex container justify-between  py-4 pb-5 max-[600px]:grid "
              style={{ maxWidth: "1124px" }}
            >
              <p className="text-left  ">
                <Link
                  to="https://www.igms.com/"
                  target="_blank"
                  className="text-[rgb(188,188,188,1)] underline underline_new"
                  style={{ textDecoration: "underline" }}
                >
                  {" "}
                  {t("Vacation rental software")}{" "}
                </Link>{" "}
                {t("& technology by iGMS")}
                <p>{t("© 2024 Fiddler Lake Resort")}</p>
              </p>
              <div className="flex gap-12">
                <Link to="https://www.facebook.com/FiddlerLake/">
                  <img src={facebookd} loading="lazy" className="w-5" />
                </Link>
                <Link to="https://www.instagram.com/fiddler_lake_resort/?hl=en">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    color="#BCBCBC"
                  />
                </Link>
              </div>
            </div>
          </div>
        </footer>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.88)",
          zIndex: 9999999999999,
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <div>
          <ClipLoader
            color={"#FFF"}
            loading={true}
            css={spinnerOverride} 
            size={35}
          />
        </div>
      </div>
    </div>
  );
};
export default TermsAndConditions;
