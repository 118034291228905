import React, { useState } from "react";
import "./ChaletsByType.css";

export default function ChaletsByTypeSlider({ chaletsImage }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % chaletsImage.length);
  };
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? chaletsImage.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="swiper-container">
      <div>
        <button className="swiper-button prev" onClick={handlePrev}>
          &#10094;
        </button>
        <button className="swiper-button next" onClick={handleNext}>
          &#10095;
        </button>
      </div>
      <div className="swiper-wrapper">
        {chaletsImage.map((image, index) => (
          <div
            key={index.toString()}
            className={`swiper-slide ${index === currentIndex ? "active" : ""}`}
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            <img
              src={image.large}
              style={{ objectFit: "cover", objectPosition: "top" }}
              loading="lazy"
              alt={`Slide ${index}`}
              className="w-full h-full"
            />
          </div>
        ))}
      </div>
      <button className="swiper-button next" onClick={handleNext}>
        &#10095;
      </button>
    </div>
  );
}
