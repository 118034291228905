import React, { useState, useEffect } from "react";
import Icons from "../../../Constants/Icon";
import i18n from "../../../../i18n";
import { useLocation } from "react-router-dom";

const LanguageSelector = ({
  languages,
  defaultLanguage,
  onLanguageChange,
  setIsOpen,
  isOpen,
}) => {
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    if (location.pathname == "/fr" || location.pathname == "/fr/") {
      return "fr";
    } else if (location.pathname == "/en" || location.pathname == "/en/") {
      return "en";
    } else {
      const storedLanguage = localStorage.getItem("selectedLanguage");
      return storedLanguage || defaultLanguage || i18n.language;
    }
  });

  const handleLanguageChange = (languageCode) => {
    onLanguageChange(languageCode);
    const newLanguage = languageCode;
    setSelectedLanguage(newLanguage);
    setIsOpen(false);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    localStorage.setItem("selectedLanguage", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const selectedLanguageName = languages.find(
    (lang) => lang.code === selectedLanguage
  )?.name;
  const displayText =
    selectedLanguageName && selectedLanguageName !== "En"
      ? selectedLanguageName.slice(0, 2)
      : "En";

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className="custom-select" style={{ width: "100%" }}>
      <div className="selected" onClick={() => setIsOpen(!isOpen)}>
        <div className="pr-3">{displayText}</div>
        <div className={`dropdown-icon ${isOpen ? "open" : ""}`}>
          {" "}
          <img src={Icons.arrowdown} loading="lazy" className="" />
        </div>
      </div>
      <ul className={`options ${isOpen ? "open" : ""}`}>
        {languages.map((language) => (
          <li
            key={language.code}
            onClick={() => handleLanguageChange(language.code)}
            className=" AkagiPro-Black text-20"
          >
            {language.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSelector;
