import React, { useEffect, useState } from "react";
import "./ChaletsByType.css";
import BackgroundImage from "../../../component/backgroundtext/backgroundimg";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import {
  BaseUrl,
  GoogleTranslateApiKey,
  TranslateApi,
} from "../../../Core/Until";
import { useDispatch } from "react-redux";
import { setLoadingFalse } from "../../../Config/Actions/loadingActions";
import { useMedia } from "../../../MediaProvider";
import ChaletsByTypeSlider from "./ChaletsByTypeSlider";

const spinnerOverride = css`
  display: block;
  margin: 0 auto;
`;
function ChaletsByType() {
  const { media } = useMedia();
  const Icons = media.Icons;

  const { t, i18n } = useTranslation();
  const currentLanguageCode = i18n.language;
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [pageComments, setPageComments] = useState(0);
  const [paginationClose, setPaginationClose] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const [showMenu, serShowMenu] = useState(null);

  const onClickHandleShow = (index) => {
    serShowMenu((prevIndex) => (prevIndex === index ? index : index));
  };
  useEffect(() => {
    if (!listings.length) return; // Skip if no sections exist

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          serShowMenu(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    const sections = document.querySelectorAll(".scroll-section");
    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, [listings]);

  useEffect(() => {
    dispatch(setLoadingFalse());
    window.scrollTo(0, 0);
    setListings([]);
    setPageComments(0);
    fetchListings(0);
  }, [location, currentLanguageCode]);
  const translateText = async (text) => {
    try {
      const response = await axios.post(
        `${TranslateApi}?key=${GoogleTranslateApiKey}`,
        {
          q: text,
          target: "fr",
        }
      );
      return response.data.data.translations[0].translatedText;
    } catch (error) {
      console.log("Translation error:", error);
      return text;
    }
  };

  const translateArray = async (dataArray, targetLang = "fr") => {
    const translatedArray = [];
    for (let item of dataArray) {
      const translatedItem = {
        ...item,
        propertyName: await translateText(item.propertyName, targetLang),
        features: {
          ...item.features,
          bedrooms: await Promise.all(
            item.features.bedrooms.map((bedroom) =>
              translateText(bedroom, targetLang)
            )
          ),
          amenities: await Promise.all(
            item.features.amenities.map((amenity) =>
              translateText(amenity, targetLang)
            )
          ),
        },
      };
      translatedArray.push(translatedItem);
    }
    return translatedArray;
  };

  const decodeHtmlEntities = (str) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = str;
    return textArea.value;
  };

  const fetchListings = async (nextPage) => {
    if (loading) return;
    document.body.style.overflow = "hidden";
    try {
      setLoading(true);
      const response = await axios.post(`${BaseUrl}api/listings`, {
        offset: 24 * nextPage,
        limit: 24,
        category: location?.state?.searchChalet ?? "Rabbit",
        // category: location?.state?.searchChalet ?? "Deer",
      });

      if (response?.data?.data?.listings?.length) {
        const newListings = response?.data?.data?.listings.map((listing) => {
          let features = {
            bedrooms: [],
            bathrooms: 0,
            amenities: [],
            squareFeet: "",
            guestsMax: 0,
          };

          if (listing?.description) {
            const description = listing.description;
            const splitDescription = description
              .split("\n")
              .map((item) => item.trim())
              .filter(Boolean);

            const guestAccessIndex = splitDescription.findIndex((line) =>
              line.includes("Guest access")
            );
            if (guestAccessIndex !== -1) {
              splitDescription.splice(guestAccessIndex); // Remove everything starting from "Guest access"
            }
            const otherAccessIndex = splitDescription.findIndex((line) =>
              line.includes("Other things to note")
            );
            if (otherAccessIndex !== -1) {
              splitDescription.splice(otherAccessIndex); // Remove everything starting from "Guest access"
            }

            if (
              splitDescription[splitDescription.length - 2].includes(
                "CITQ Registration number"
              ) ||
              splitDescription[splitDescription.length - 2].includes(
                "Registration number"
              )
            ) {
              splitDescription.splice(splitDescription.length - 2, 2);
            }
            const propertyFeatures = splitDescription.slice(
              splitDescription.indexOf("Property features:") + 1
            );
            propertyFeatures.forEach((item) => {
              const decodedItem = decodeHtmlEntities(item);
              const cleanedItem = decodedItem
                .replace(/^[•\-\t\s]+|^[]+/, "")
                .trim();
              if (/Bedroom/i.test(cleanedItem)) {
                features.bedrooms.push(cleanedItem);
              } else if (/bathroom/i.test(cleanedItem)) {
                features.bathrooms = parseInt(
                  cleanedItem.match(/\d+/)?.[0] || 0,
                  10
                );
              } else if (/square feet/i.test(cleanedItem)) {
                features.squareFeet = cleanedItem.match(/\d+/)?.[0];
              } else if (/guests maximum/i.test(cleanedItem)) {
                features.guestsMax = parseInt(
                  cleanedItem.match(/\d+/)?.[0] || 0,
                  10
                );
              } else {
                features.amenities.push(cleanedItem);
              }
            });
            // features.amenities = features.amenities.slice(
            //   0,
            //   features.amenities.length - 3
            // );
          }

          return {
            websiteListingUid: listing?.websiteListingUid,
            images: listing.images,
            propertyName: listing.listingName.replace(/^Chalet\s+/i, ""),
            features,
          };
        });

        newListings.sort((a, b) => {
          const numA = parseInt(a.propertyName.match(/\d+/)?.[0] || 0, 10);
          const numB = parseInt(b.propertyName.match(/\d+/)?.[0] || 0, 10);
          return numA - numB;
        });

        const translatedNewListings =
          localStorage.getItem("selectedLanguage") == "fr"
            ? await translateArray(newListings, "fr")
            : newListings;
        setListings((prev) => [...prev, ...translatedNewListings]);
      }
      setPaginationClose(response?.data?.data?.searchCompleted);
    } catch (error) {
      setListings([]);
      setPageComments(0);
    } finally {
      setTimeout(() => {
        document.body.style.overflow = "unset";
        setLoading(false);
      }, 1000);
    }
  };

  const chaletTypeData = [
    {
      name: location?.state?.searchChalet ?? "Rabbit",
      height: "100vh",
      bgimg:
        location?.state?.searchChalet == "Rabbit"
          ? Icons.chalets_rabbit
          : location?.state?.searchChalet == "Bear"
          ? Icons.chalets_bear
          : location?.state?.searchChalet == "Deer"
          ? Icons.chalets_deer
          : location?.state?.searchChalet == "Moose"
          ? Icons.chalets_moose
          : Icons.Elk,
    },
  ];

  useEffect(() => {
    const handleWindowScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const clientHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const scrollHeight = document.documentElement.scrollHeight;

      if (scrollTop + clientHeight + 800 >= scrollHeight) {
        if (!paginationClose && !loading) {
          setPageComments((prevPage) => {
            const nextPage = prevPage + 1;
            fetchListings(nextPage);
            return nextPage;
          });
        }
      }
    };

    const throttleScroll = () => {
      if (!loading) {
        requestAnimationFrame(handleWindowScroll);
      }
    };

    window.addEventListener("scroll", throttleScroll);
    return () => window.removeEventListener("scroll", throttleScroll);
  }, [paginationClose, loading]);

  const urls = {
    en: "https://reservations.fiddlerlakeresort.com/",
    fr: "https://reservations.fiddlerlakeresort.com/fr/",
  };
  const currentUrl = urls[currentLanguageCode] || urls.en;
  return (
    <div className="min_ChaletsByType">
      <section className="ACTIVITIES_data family_fillte ">
        <BackgroundImage Sauveur={chaletTypeData} />
      </section>

      <div className="navigation">
        <div
          className="navigation_Scroll_hide"
          style={{ maxHeight: "60vh", overflow: "auto" }}
        >
          {listings.map((chalet, index) => (
            <div key={index.toString()} style={{ display: "flex" }}>
              <button
                onClick={() => {
                  const targetElement = document.getElementById(
                    chalet.websiteListingUid
                  );
                  if (targetElement) {
                    const targetPosition =
                      targetElement.getBoundingClientRect().top +
                      window.pageYOffset;
                    window.scrollTo({
                      top: targetPosition - 130,
                      behavior: "smooth",
                    });
                    onClickHandleShow(chalet.websiteListingUid);
                  }
                }}
                className={`nav-button ${
                  showMenu === chalet.websiteListingUid ? "active-button" : ""
                }`}
                onMouseEnter={() => setHoveredMenu(chalet.websiteListingUid)} // Set hover
                onMouseLeave={() => setHoveredMenu(null)} // Clear hover
              >
                {hoveredMenu === chalet.websiteListingUid
                  ? chalet.propertyName.toUpperCase()
                  : showMenu === chalet.websiteListingUid
                  ? chalet.propertyName.toUpperCase()
                  : t(chalet.propertyName.match(/\d+/)[0]).toUpperCase()}
              </button>
            </div>
          ))}
        </div>
      </div>

      {listings.map((chalet, index) => (
        <section
          style={{ paddingLeft: 35, paddingRight: 35 }}
          // className="container"
          className={`scroll-section container ${
            showMenu === chalet.websiteListingUid ? "active-section" : ""
          }`}
          key={index.toString()}
          id={chalet.websiteListingUid}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className=""
          >
            <h2 className="chalet-title Alfarn text-48">
              {chalet.propertyName}
            </h2>
            <Link to={`${currentUrl}property/${chalet?.websiteListingUid}`}>
              <button className=" uppercase bg-lightyellow text-white  py-8 max-[767px]:bg-sky-768 max-[767px]:w-16 book_button md:mx-2 book_now AkagiPro-Black text-20 book-button">
                {t("book now")}
              </button>
            </Link>
          </div>
          <div style={{ paddingBottom: "25px" }}>
            <div className="image-gallery ">
              <div className="main-image animation_img">
                <ChaletsByTypeSlider chaletsImage={chalet.images} />
              </div>
              <div className="property-features">
                <h4>{t("Property Features:")}</h4>
                <ul style={{}} className="text-20">
                  {/* <li>{chalet?.features?.bedrooms[0]}</li> */}
                  <div className="chalets-feature-item">
                    <img
                      src={Icons.ic_people_icon}
                      className="people_icon_style"
                    />
                    <span className="chalets-feature-text">
                      {chalet?.features?.guestsMax}
                    </span>
                  </div>
                  <div className="chalets-feature-item">
                    <div className="circle-container">
                      <span className="black-circle"></span>
                    </div>
                    <span className="chalets-feature-text">
                      {chalet?.features?.bedrooms[0].replace(/\b\w/g, (char) => char.toUpperCase())}
                    </span>
                  </div>

                  {chalet?.features?.bedrooms.slice(1).map((item, index) => (
                    <div
                    key={index.toString()}
                      style={{ marginLeft: "20px",alignItems:"start" }}
                      className="chalets-feature-item"
                    >
                      <span style={{ color: "#777", fontSize: "1em" }} className="">
                        -
                      </span>
                      <span
                        style={{ color: "#777", fontSize: "0.8em" }}
                        className="chalets-feature-text"
                      >
                        {item}
                      </span>
                    </div>
                  ))}
                  {/* <li>
                    {chalet?.features?.bathrooms} {t("Bathrooms")}
                  </li> */}

                  <div className="chalets-feature-item">
                    <div className="circle-container">
                      <span className="black-circle"></span>
                    </div>
                    <span className="chalets-feature-text">
                      {chalet?.features?.bathrooms} {t("Bathrooms")}
                    </span>
                  </div>
                  {chalet?.features?.amenities.map((item, index) => (
                    <div key={index.toString()} className="chalets-feature-item">
                    {/* <img
                      src={Icons.ic_ameniti_pool}
                      className="people_icon_style"
                    /> */}
                    <div className="circle-container">
                      <span className="black-circle"></span>
                    </div>
                      <span className="chalets-feature-text">{item}</span>
                    </div>
                  ))}
                  {/* <li>
                    {chalet?.features?.squareFeet} {t("Square feet")}
                  </li> */}
                  <div className="chalets-feature-item">
                    <div className="circle-container">
                      <span className="black-circle"></span>
                    </div>
                    <span className="chalets-feature-text">
                      {new Intl.NumberFormat().format(chalet?.features?.squareFeet)} {t("Square feet")}
                    </span>
                  </div>
                  {/* <li>
                    {chalet?.features?.guestsMax} {t("Guests maximum")}
                  </li> */}
                  {/* <div className="chalets-feature-item">
                    <img
                      src={Icons.ic_people_icon}
                      className="people_icon_style"
                    />
                    <span className="chalets-feature-text">
                      {chalet?.features?.guestsMax} {t("Guests maximum")}
                    </span>
                  </div> */}
                </ul>
              </div>
            </div>
          </div>
        </section>
      ))}
      {listings.length == 0 && (
        <h2
          style={{ textAlign: "center" }}
          className="uppercase p-3 text-lightyellow bg-sky-768 max-w-[90%] w-auto mx-auto sm:max-w-[60%] text-center AkagiPro-Black text-20"
        >
          {t("Not Any Chalets Found")}
        </h2>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.88)",
          zIndex: 9999999999999,
          visibility: loading ? "visible" : "hidden",
        }}
      >
        <div>
          <ClipLoader
            color={"#FFF"}
            loading={true}
            css={spinnerOverride}
            size={35}
          />
        </div>
      </div>
    </div>
  );
}

export default ChaletsByType;
