import React from "react";
import GroupsEvents from "../../../component/Groups & Events/Custompackge";
import { useEffect, useState, useRef } from "react";
import Bookfooter from '../../../component/footer/Bookfooter';
import { useTranslation } from 'react-i18next';
import GroupsEventsd from "../../../component/Groups & Events/Copororatrgroup";
import Drinkingbg from "../../../component/backgroundtext/resortsbg";
import Contact from "../../../component/contactus/Contact";
import { useMedia } from "../../../MediaProvider";

function Corporate() {

    
    const { media } = useMedia();
      const Icons = media.Icons;

    const Corporategroupsd = [{

        Wedding: "Weddings ",
        Corporate: "Family & Friends ",
        Romantic: "Celebrate your dream wedding in a uniquely charming and romantic setting. We offer you an extraordinary and customized experience. We’ll help you create memories that will be cherished for a lifetime.",
        Birds: "Learn more",
         fabulous: "Experience an unforgettable family reunion, birthday, anniversary or holiday celebration at Fiddler Lake Resort. We offer the ultimate venue for any group event, with or without children.",
        img: Icons.Family10,
        imgs: Icons.wedding123,
        Link: "/weddings",
        textcontant: "",
    }]
    const contactdata  =[{
        name:"Corporate Event Reservation",
        planning:"Start planning your next corporate event today. We invite you to contact us today at",
        complete:" or complete the contact form below. See you soon!"
    }]
    const Corporategroups = [{
        Romantic: "Stay focused on your business goals while our Customer Experience Team ensures that all details of your team building activities and catering services are executed flawlessly."
    }]
    const Outdoordata = [{
        img: Icons.Resort6,
        name: "Luxurious <br class='max-[767px]:hidden'/> Chalets",
        title: "Our larger chalets are perfect for welcoming groups of up to 40 people. Host your business meetings at one of our many chalets and allow the members of your team to enjoy everything that Fiddler Lake Resort has to offer.",
    },
    {
        img: Icons.Resort7,
        name: "Outdoor Gathering Spaces",
        title: "Meet in the fresh outdoors and get your creative juices flowing. If you need protection from the weather during the summer season, ask us about renting a big top.",
    },
    {
        img: Icons.coporet_ic,
        name: "Amenities to Relax & Unwind",
        title: "Indulge in a well-deserved break with a dip in the pool, a soak in the hot tub or a stroll to the lake to marvel at the breathtaking views of the Laurentians.",
    }
    ]
    const Breakfastdata = [{
        name: "BREAKFAST",
        title: "Start your day off right with a hearty breakfast of pancakes, potato cakes, breakfast sausages, maple-glazed ham, bacon, and other delicacies.",
        Buffet: "The Pays-d’en-haut Breakfast",
    
    },
    {
        name: "BREAKFAST",
        title: "A delicious, flavour-filled basket filled with fresh fruit, fruit jam, fine chocolates, and granola.",
        Buffet: "Fiddler Lake’s Morning Basket"
    },
    {
        name: "LUNCH",
        title: "A varied menu features whatever is available throughout the changing seasons. Always best enjoyed in a relaxed atmosphere!",
        Buffet: "The Food Truck"
    },
    {
        name: "LUNCH",
        title: "A delectable choice of sandwiches, salad or soup, and a delightful cupcake to top it off!",
        Buffet: "Fiddler’s Lake Lunch Boxes"
    },
    {
        name: "LUNCH",
        title: "Anepicurean feast of potato salad, old-fashioned shrimp and eggs, tomato and bocconcini salad, homemade ravioli with rosé sauce, chicken parmesan, Atlantic salmon, lemon and caper olive oil, and a grilled vegetable platter.",
        Buffet: "The Gourmet Buffet"
    },
    {
        name: "LUNCH",
        title: "Participate in a culinary challenge who gives each team a box of surprise ingredients. Enjoy teambuilding at its best!",
        Buffet: "The 'MasterChef' Concept",
    
    },
    {
        name: "DINNER",
        title: "Enjoy a four-course gastronomic menu in the comfort of your chalet. Dishes include Land and sea, lobster Thermidor, and Quebec veal filet mignon. It’s sure to delight the most discerning palates!",
        Buffet: "Your Private Chef",
    },
    {
        name: "DINNER",
        title: "Everyone loves BBQ, especially when it includes Angus beef sirloin burgers, homemade ribs preserved and slow cooked in duck fat, 10 choices of salads, homemade fries, or even a homemade poutine with foie gras sauce. And we didn’t forget a scrumptious dessert of fresh lime tart or chocolate fondue.",
        Buffet: "A BBQ Bash",
        width: "55%",
        textAlign: "left"
    }
    ]
    const Organizer = [
        {
            img: Icons.Resort12,
            name: "Team Building Activities",
            title: "Organize stimulating activities that encourage friendly competition and collaboration in the heart of nature!"
        }, {
            img: Icons.Resort13,
            name: "On-Site Sports",
            title: "Enjoy group activities such as canoeing, kayaking, hiking, snowshoeing, basketball, tennis, pickleball and much more!"
        },
        {
            img: Icons.Resort11,
            name: "Local Activities",
            title: "Plan a group activity in the heart of the Saint-Sauveur Valley. Within minutes of the Resort, your team can enjoy golf, motor racing, ziplining, skiing and much more!"
        }
    
    ]

    const [overHeight, setOverHeight] = useState(false);
    const pRef = useRef(null);
    useEffect(() => {
        const handleResize = () => {
            if (pRef.current.offsetHeight < pRef.current.scrollHeight) {
                setOverHeight(true);
            } else {
                setOverHeight(false);
            }
        };
        handleResize();
    }, []);
    const { t } = useTranslation();
    const Depending = [{
        Rentals: "CORPORATE GROUPS ",
        bgimg: Icons.CorporateBanner,
       height:"100vh"
    }]
    return (
        <div className="">
              <div className='family_fillte copororat_img'> 
                <Drinkingbg Depending={Depending} />
            </div>
            <section className='bg-skyYellow flex items-center justify-center relative md:mt-20 max-[767px]:mt-10 lg:min-h-[520px] md:min-h-[428px]' >
                <div className='container px-8 '>
                    <div className='grid grid-cols-2 max-[352px]:grid-cols-1 md:gap-14 max-[767px]:gap-5'>
                        <div className='flex justify-left items-center max-[767px]:py-5' >
                            <div >
                                <h1 className=" text-64 Alfarn " >{t("Business Meetings & Team Building")}</h1>
                                <p className="text-20 text-justify AkagiPro-book md:mt-8">
                                    {t("Whether you are hosting a seminar, conference, training session, business meeting or team building activity, Fiddler Lake Resort is the ideal destination to exchange ideas and to re-energize your team.")}
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className='grid w-full h-full  items-center '>
                                <div className=' relative  '>
                                    <div className='animation_img'>
                                        <img loading="lazy" className=' w-full h-full object-contain img_animation ' src={Icons.Resort111} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{
                backgroundImage: 'url(' + Icons.fiddlerchalet + ')',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",

            }} className="bg-skyYellow  md:py-7 max-[767px]:py-3 md:mb-20 max-[767px]:mt-7 md:mt-20" >
                <div className="container">
                    <h1 className="text-black md:mt-3 md:mb-6 text-64 Alfarn text-center" style={{ lineHeight: "66px" }}>{t("Fresh Air & Fresh Ideas")}</h1>
                    <p className="text-20 AkagiPro-book text-center max-w-[860px] mx-auto ">{t("Break out of the routine! The beauty and tranquility of Fiddler Lake can surely rejuvenate your team's spirit and creativity. Allow your team to get inspired while breathing in fresh air and breathing out fresh ideas!")}</p>
                    <div className="flex gap-4 items-center max-[767px]:mt-3 md:mt-8">
                        <div className="max-[767px]:w-full md:w-[70%]">
                            <h2 className="text-32 md:text-center  Akagi-Pro-blacks  font-bold max-w-[390px] mx-auto" style={{ fontWeight: "700" }} >{t("A Business Retreat Made to Measure")}</h2>
                            <p className="text-20 AkagiPro-book md:mt-3 max-[767px]:mt-1 md:text-center max-w-[390px] mx-auto">{t("Fiddler Lake Resort is a preferred choice for corporate groups of all kinds due to its flexibility and personalized offerings.")}</p>
                        </div>
                        <div style={{ width: "100%" }} className="-mb-20 max-[767px]:hidden animation_img">
                            <img src={Icons.Resort3} loading="lazy" className=" img_animation" />
                            </div>
                        <img src={Icons.rsevenements}  loading="lazy" className="-mb-7 md:hidden max-[767px]:w-full max-[330px]:w-[40%]" />

                    </div>
                </div>
            </section>
            <GroupsEvents Corporategroups={Corporategroups} />
            <section className="bg-skyYellow md:p-10 max-[767px]:pt-5 max-[767px]:pb-56  max-[500px]:pb-32 md:pt-12 md:pb-56 ">
                <div className="container">
                    <div className="animation_img ">
                        <div style={{
                            backgroundImage: 'url(' + Icons.Resort55 + ')',
                            backgroundSize: "cover",
                            backgroundPosition:"center",
                            backgroundRepeat: "no-repeat",
                        }} className=" flex justify-center md:min-h-[600px] max-[767px]:min-h-[265px] max-[767px]:p-5 max-[500px]:min-h-[100px] items-center second_menu" >
                            <h1 className="text-white mt-3 mb-6 text-48 Alfarn text-center  md:max-w-[900px]" style={{ lineHeight: "53px" }}>{t("An idyllic private resort that")}
                                <br className="hidden" /> {t("will inspire your next big idea")}</h1>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="grid md:grid-cols-3 md:gap-3 max-[767px]:gap-[280px] max-[500px]:gap-[180px]  h-full mt-14">
                    {Outdoordata.map((item,index) => (
                        <div key={index.toString()} className=" relative  lg:mt-20">
                            <div className="animation_img  w-full absolute lg:-top-72 md:-top-[14rem] max-[767px]:-top-64  max-[500px]:-top-40 object-cover max-[767px]:h-[300px] max-[500px]:h-[200px] lg:h-[303px] md:h-[223px]" >
                                <img src={item.img} loading="lazy" className="h-full max-[767px]:object-cover w-full img_animation" style={{overflowClipMargin:"unset"}} /></div>
                            <div style={{
                                border: "1.5px solid #573B0F",
                                outline: "1.5px solid #573B0F",
                                outlineOffset: "-5px",
                                marginLeft: "50px",
                                marginRight: "10px",
                                position: "relative",
                                height: "100%",
                            }}
                                className="md:pb-5">

                                <div className="bg-white black_shoadow max-[767px]:p-4 md:p-7 -ml-10 mr-5 h-full -mt-5 z-50 relative">
                                    <h2 className="text-30 text-center Akagi-Pro-blacks font-bold uppercase" dangerouslySetInnerHTML={{__html:t(item.name)}} />
                                    <p ref={pRef} className="text-gray AkagiPro-book text-20 mb-5 text-justify">
                                        {t(item.title)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <section className="bg-skyYellow md:py-7 max-[767px]:py-3 max-[767px]:pb-5  md:mb-32 md:mt-16 max-[767px]:mt-6 max-[767px]:mb-12 " style={{
                background: "none",
                marginBottom: "0px"
            }}  >
                <div className="container" style={{ display: "none" }}>
                    <h1 className="text-black md:mt-3 md:mb-6 text-48 Alfarn text-center" style={{ lineHeight: "52px" }}>{t("Tasty experiences that")} <br /> {t("feed  the imagination")}</h1>
                    <p className="text-20 AkagiPro-book text-center">{t("Delicious meals are key componentsof your stay. The chefs of the region are proud to offer you an unmatched ")}<br /> {t("diversity of flavours. We treat your taste buds while respecting your budget!")}</p>
                    <div className="grid grid-cols-2 max-[340px]:grid-cols-1 gap-3 md:mt-10 max-[767px]:mt-3 md:-mb-14 max-[767px]:-mb-7 ">
                        <div className="animation_img">
                            <img loading="lazy" src={Icons.Resort9} className="-mb-12 w-full h-full object-cover img_animation" />
                        </div>
                        <div className="w-full flex justify-center md:py-10 max-[767px]:pb-6 max-[767px]:pt-3">
                            <div style={{ maxWidth: "476px" }}>
                                <h2 className="text-32  AkagiPro-Black font-bold" style={{ lineHeight: "30px" }} >{t("Create your own kitchen brigade!")}</h2>
                                <p className="text-20 AkagiPro-book  text-justify text-gray">{t("Why not rally your team around a food preparation project? Our chalets are fully equipped so your teammates can collaborate on a gastronomic goal.")}</p>
                                <h2 className="text-32  AkagiPro-Black font-bold md:mt-5 max-[767px]:mt-4" style={{ lineHeight: "30px" }} >{t("Our culinary partners")}</h2>
                                <p className="text-20 AkagiPro-book  text-gray">{t("The Laurentians is home to many fine restaurants and celebrated chefs. We’re proud to team up with the best caterers in the region.")}</p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            <section style={{
                backgroundImage: 'url(' + Icons.Resort51 + ')',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                display: "none",
            }} className=" py-7 md:pb-16 md:mb-16 max-[767px]:mb-12 mt-0" >
                <div className="container">
                    <h1 className="text-white md:mt-3 mb-0 text-48 Akagi-Pro-blacks text-center capitalize" style={{ lineHeight: "52px" }}>{t("Curious about how we celebrate")} <br className="max-[767px]:hidden" />{t("gastronomy? Here’s a small glimpse")}</h1>
                    <div className="flex justify-center w-full">
                        <div className="flex flex-wrap justify-center max-[767px]:mt-2">
                            {Breakfastdata.map((items,index) => (
                                <div key={index.toString()} className="celebrate"
                                    style={{
                                        border: "1.5px solid white",
                                        outline: "1.5px solid white",
                                        outlineOffset: "-5px",
                                        marginLeft: "50px",
                                        marginRight: "10px",
                                        position: "relative",
                                        paddingBottom: "20px",
                                        width: `${items.width || "28%"}`,
                                        marginTop: "60px",
                                        padding: "0px",

                                    }}>
                                    <div className="bg-white  black_shoadow p-8 pb-10 -ml-10 md:mr-5 max-[767px]:mr-3 md:-mt-5 max-[767px]:-mt-3 z-50 relative h-full" style={{ textAlign: `${items.textAlign || "center"}` }}>
                                        <h2 className="text-30 text-lightyellow  AkagiPro-Black max-[767px]:text-left ">{t(items.name)}</h2>
                                        <h2 className="text-30 lg:min-h-[40px] md:min-h-[40px] max-[767px]:text-left  AkagiPro-Black ">{t(items.Buffet)}</h2>
                                        <p className="text-20 AkagiPro-book lg:mt-4 mb-3 text-gray text-justify">{t(items.title)}</p>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>

                </div>
            </section >
            <section className="bg-skyYellow md:p-10 max-[767px]:pt-5 max-[767px]:pb-72  max-[500px]:pb-52 md:pt-16 md:pb-56 ">
                <div className="container">
                    <div style={{
                        backgroundImage: 'url(' + Icons.Resort555 + ')',
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition:"bottom"
                    }} className="animation_img flex justify-center md:min-h-[600px] max-[767px]:min-h-[265px] max-[767px]:p-5 max-[500px]:min-h-[100px] items-center " >
                        <div>
                        <h1 className="text-white mt-3 mb-6 text-48 Alfarn text-center" style={{ lineHeight: "53px" }}>{t("A VARIETY OF ACTIVITIES")}<br />{t("THAT FOSTER TEAM SPIRIT")}</h1>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="grid md:grid-cols-3 md:gap-3 max-[767px]:gap-[280px] max-[500px]:gap-[200px]  h-full md:mt-40">
                    {Organizer.map((item,index) => (
                        <div key={index.toString()} className=" relative">
                            <div className="animation_img w-full absolute lg:-top-64 md:-top-48 max-[767px]:-top-64  max-[500px]:-top-44 object-cover max-[767px]:h-[300px] max-[500px]:h-[200px] lg:h-[303px] md:h-[223px]">
                                <img loading="lazy" src={item.img} style={{objectFit:"cover",objectPosition:"bottom",overflowClipMargin:"unset"}} className=" max-[767px]:object-cover  h-full w-full img_animation" /></div>
                            <div style={{
                                border: "1.5px solid #573B0F",
                                outline: "1.5px solid #573B0F",
                                outlineOffset: "-5px",
                                marginLeft: "50px",
                                marginRight: "10px",
                                position: "relative",

                                height: "100%",

                            }}
                                className="md:pb-5">

                                <div className="bg-white black_shoadow md:p-7 md:pb-12 max-[767px]:p-5 -ml-10 mr-5 h-full -mt-5 z-50 relative">
                                    <h2 className="text-30 text-left Akagi-Pro-blacks  font-bold">{t(item.name)}</h2>
                                    <p ref={pRef} className="text-gray AkagiPro-book text-20 mb-3 text-justify">
                                        {t(item.title)}
                                    </p>
                                </div>
                            </div>
                        </div>


                    ))}
                </div>
            </div>
            <section style={{
                backgroundImage: 'url(' + Icons.Resort14 + ')',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }} className=" md:pt-14 md:pb-16 max-[767px]:py-8  md:mb-12 md:mt-20   max-[767px]:mb-7  max-[767px]:mt-10" >
                <div className="container">
                    <h2 className="text-30 max-[767px]:mb-1 text-white  AkagiPro-Black text-center ">{t("BUSINESS CLASS SERVICES FOR")}</h2>
                    <h1 className=" mb-8 text-48 Alfarn text-white text-center capitalize" style={{ lineHeight: "53px" }}>{t("productive meetings")}</h1>
                    <div className="flex grid-cols-2 justify-center max-[767px]:gap-20 md:gap-52 md:mt-32 max-[767px]:mt-4">
                        <div className="md:min-w-[344px]" >
                            <div className="w-full flex justify-center md:mb-10 max-[767px]:mb-4">
                                <img loading="lazy" src={Icons.Resort15} className="md:h-20 max-[767px]:h-[45px]" />
                            </div>
                            <h2 className="text-30 text-white  AkagiPro-Black text-center ">{t("Meeting Room Equipment")}</h2>
                            <p className="text-20 AkagiPro-book text-white  text-center">{t("Projectors and screens available onsite")}<br />{t("")}</p>
                        </div>
                        <div  className="md:min-w-[344px]" >
                            <div className="w-full flex justify-center md:mb-10 max-[767px]:mb-4">
                                <img loading="lazy" src={Icons.Resort16} className="md:h-20 max-[767px]:h-[45px]" />
                            </div>
                            <h2 className="text-30 text-white  AkagiPro-Black text-center ">{t("Connectivity")}</h2>
                            <p className="text-20 AkagiPro-book text-white  text-center">{t("Unlimited Internet")}</p>
                        </div>
                    </div>
                </div>
            </section>
            <GroupsEventsd Corporategroupsd={Corporategroupsd} />
            <div className="md:mb-12 max-[767px]:mb-8">
                <Contact  contactdatanew={contactdata}/>
            </div>
            <Bookfooter />
        </div >
    )
}
export default Corporate;