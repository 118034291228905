import React, { useState } from 'react';
import Icons from '../../../Constants/Icon';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { setRedirect } from "../../../Config/Actions/redirectchalets";
import { useDispatch } from 'react-redux';

const Customdrop = ({ languages, handleItemselect, displayText }) => {
    const [isOpendrop, setIsOpendrop] = useState(false);
    const handleMouseLeave = () => {
        setIsOpendrop(false);
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleItemClick = (itmes) => {
        navigate(itmes.route);
    }
    const handleItemSelect = (language) => {
        handleItemselect(language)
        if (language.id) {
            dispatch(setRedirect(language.id));
        }
    }
    const { t } = useTranslation();
    return (
        <div className="custom-select" style={{ width: "100%" }} onMouseLeave={() => handleMouseLeave()}>
            <div className="selected" onMouseOver={() => setIsOpendrop(!isOpendrop)} >
                <div className='pr-3'>
                    {displayText.map((itmes,index) => (
                        <div key={index.toString()} className='md_p' to={itmes.route} onClick={() => handleItemClick(itmes)}  >{t(itmes.displayText)}</div>
                    ))}
                </div>
                <div className={`dropdown-icon ${isOpendrop ? 'open' : ''}`}> <img src={Icons.arrowdown} className='' /></div>
            </div>
            <div style={{marginTop:'-5px',position:'relative',}}>
            <ul className={`lg:mt-0 options ${isOpendrop ? 'open' : ''}`}  >
                {languages.map(language => (
                    <Link  key={language.name} to={language.Route} state={{searchChalet:language.name}}>
                        <li key={language.code} onClick={() => handleItemSelect(language)}>
                            {t(language.name)}
                        </li>
                    </Link>
                ))}
            </ul>
            </div>
        </div>
    );
};

export default Customdrop;
