import React, { useState, useEffect } from 'react';
import BackgroundImage from "../../../component/backgroundtext/backgroundimg";
import Bookfooter from "../../../component/footer/Bookfooter";
import Contactus from "../../../component/contactus/INcontactus";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ContactGroup from "../../../component/Groups & Events/contactGroup";
import { useSelector } from "react-redux";
import { useMedia } from '../../../MediaProvider';

function ContactUs() {

 
    const { media } = useMedia();
      const Icons = media.Icons;

    const sectionimg = {
        backgroundImage: 'url(' + Icons.fiddlerchalet + ')',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
    };
    const sectionimgs = {
        backgroundImage: 'url(' + Icons.fiddlerchalet + ')',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
    }
    const Housekeepings = [{
        name: "Maintenance Technician"
    },
    {
        name: "Housekeeping Attendant"
    },
    {
        name: "Amenities and Activities Attendant "
    }
    ]

    const [responsiveHeight, setResponsiveHeight] = useState(
        window.innerWidth > 768 ? "100vh" : "30vh"
    );
    useEffect(() => {
        const handleResize = () => {
            const newHeight = window.innerWidth > 768 ? "100vh" : "30vh";
            setResponsiveHeight(newHeight);
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const Sauveur = [{
        name: "Contact Us",
        height: responsiveHeight,
        bgimg: Icons.Family9,
        montreal: "PLAN YOUR IDEAL CHALET GETAWAY TODAY"
    }];

    const handleLinkClick = () => {
        const element = document.getElementById("service");
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 270,
                behavior: 'smooth'
            });
        }
    };
    const Corporategroupsd = [{
        Wedding: "EV chargers readily available",
        Corporate: "Download the Fiddler Lake Resort map! ",
        Romantic: "In an effort to contribute to environmental sustainability, there are electric vehicle charging stations on site at most of our chalets and at our Recreation Centre.",
        img: Icons.map_final,
        imgs: Icons.carmode1_,
        Link: "/weddings",
        textcontant: "",
    }]
    const redirect = useSelector((state) => state.redirect);
    useEffect(() => {
        const element = document.getElementById(redirect);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 270,
                behavior: 'smooth'
            });
        }
    });
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
            }, error => {
                console.error('Error getting location: ', error);
            });
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    }, []);

    const generateMapLink = () => {
        if (latitude && longitude) {
            return `https://www.google.ca/maps/place/Fiddler+Lake+Resort/@45.80959,-74.2443817,17z/data=!3m1!4b1!4m5!3m4!1s0x4ccf2428f3cf5c6f:0x67077b4257d77482!8m2!3d45.80959!4d-74.242193?shorturl=1`;
        }
        return "https://www.google.ca/maps/place/Fiddler+Lake+Resort/@45.80959,-74.2443817,17z/data=!3m1!4b1!4m5!3m4!1s0x4ccf2428f3cf5c6f:0x67077b4257d77482!8m2!3d45.80959!4d-74.242193?shorturl=1";
    };
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    const urls = {
        en: "https://reservations.fiddlerlakeresort.com/",
        fr: "https://reservations.fiddlerlakeresort.com/fr/"
    };
    const currentUrl = urls[currentLanguageCode] || urls.en;
    return (
        <div>
            <div className="family_fillte" >
                <BackgroundImage Sauveur={Sauveur} />
            </div>
            <section className="md:mt-16 max-[767px]:mt-5 mb-10 " >
                <div className="container"  >
                    <div className="grid md:grid-cols-2" id="service">
                        <div className='grid align-baseline'>
                            <section className="text-center mb-10 ">
                                {latitude && longitude ? (
                                    <a className='text-black' href={generateMapLink()} target="_blank" rel="noopener noreferrer">
                                        <h1 className="text-black md:mt-0 max-[767px]:mt-5 max-[767px]:mb-5  md:text-left max-[767px]:text-center   text-48 Alfarn capitalize">{t("General information")}</h1>
                                        <div className="grid gap-3 text-20 mt-10 max-[767px]:m-auto max-[767px]:max-w-[339px]" >
                                            <div className="flex gap-2 items-top max-[767px]:items-center  text-left " ><img src={Icons.home4} loading="lazy" className="md:h-6 max-[767px]:h-5" />{t("1000 Route 329, Mille-Isles, Quebec J0R 1A0")} </div>
                                            <div className="flex gap-2 items-top  max-[767px]:items-center  text-left"><img src={Icons.home3} loading="lazy" className="md:h-6 max-[767px]:h-5" />{t("1-800-721-0371")} </div><div className='hidden'>721-0371</div>
                                            <div className="flex gap-2 items-top  max-[767px]:items-center  text-left"><img src={Icons.home2} loading="lazy" className="md:h-6 max-[767px]:h-5" />{t("reservation@fiddlerlakeresort.com")} </div>
                                            <div className="flex gap-2 items-top  max-[767px]:items-center  text-left"><img src={Icons.home5} loading="lazy" className="md:h-6 max-[767px]:h-5" />{t("Establishment number : 980383")} </div>
                                        </div>
                                    </a>
                                ) : (
                                    <a className='text-black' href={generateMapLink()} target="_blank" rel="noopener noreferrer">
                                        <h1 className="text-black md:mt-0 max-[767px]:mt-5 max-[767px]:mb-5  md:text-left max-[767px]:text-center   text-48 Alfarn capitalize">{t("General information")}</h1>
                                        <div className="grid gap-3 text-20 mt-10 max-[767px]:m-auto max-[767px]:max-w-[339px]" >
                                            <div className="flex gap-2 items-top max-[767px]:items-center  text-left " ><img src={Icons.home4}  loading="lazy" className="md:h-6 max-[767px]:h-5" />{t("1000 Route 329, Mille-Isles, Quebec J0R 1A0")} </div>
                                            <div className="flex gap-2 items-top  max-[767px]:items-center  text-left"><img src={Icons.home3} loading="lazy" className="md:h-6 max-[767px]:h-5" />{t("1-800-721-0371")} </div><div className='hidden'>721-0371</div>
                                            <div className="flex gap-2 items-top  max-[767px]:items-center  text-left"><img src={Icons.home2} loading="lazy" className="md:h-6 max-[767px]:h-5" />{t("reservation@fiddlerlakeresort.com")} </div>
                                            <div className="flex gap-2 items-top  max-[767px]:items-center  text-left"><img src={Icons.home5} loading="lazy" className="md:h-6 max-[767px]:h-5" />{t("Establishment number : 980383")} </div>

                                        </div>
                                    </a>
                                )}
                            </section>
                        </div>
                        <div>
                            <Contactus />
                        </div>
                    </div>
                </div>
            </section>
            <div className="container" >
                <div className='flex align-center items-center '>
                    <div className=' w-[50%]' >
                        <div className="flex gap-2 items-top  max-[767px]:items-center  text-left ml-0"> <div style={{ marginBottom: "20px", textDecoration: "underline!important" }} className='text-black font-bold text-30' to='/faq'>{t("Questions about your stay?")}  </div></div>
                        <div style={{ color: "#777" }} className='text-20'>{t("Consult our")}<Link to='/faq' className='text-black'> {t("FAQ ")} </Link>{t("page.")}</div>
                    </div>
                    <img src={Icons.GeneralInformation} loading="lazy" className=' w-[50%]' />
                </div>
            </div>
            <div className='mb-16'>
                <ContactGroup Corporategroupsd={Corporategroupsd} />
            </div>

            <section className="bg-skyYellow md:my-20 max-[767px]:my-7" style={{ display: "none" }}>
                <div className="container">
                    <div className="grid grid-cols-2 max-[767px]:py-5 md:py-10 ">
                        <div className="flex flex-wrap justify-between ">
                            <div className="w-1/2 flex items-center">
                                <div>
                                    <h2 className="text-30 AkagiPro-Black  mb-0">{t("Property Sales")}</h2>
                                    <p className="text-20 AkagiPro-book mb-0">{t("For more information about property sales, please write us at")}</p>
                                    <p className="text-20 AkagiPro-Black text-treed mb-0 " >{t("lorem12@gmail.com")}</p>
                                </div>
                            </div>
                            <div className="w-1/2 flex items-center justify-end max-[491px]:-mr-12 max-[400px]:-mr-16 max-[360px]:-mr-28">
                                <div>
                                    <p className="text-20 AkagiPro-book mb-0"> {t("GENERAL MANAGER")}</p>
                                    <h2 className="text-30 AkagiPro-Black  mb-0">{t("John Doe")}</h2>
                                    <p className="text-20 AkagiPro-Black text-treed mb-0 " >{t("john35d@gmail.com")}</p>
                                </div>
                            </div>
                            <div className="w-full flex items-center justify-center mt-5">
                                <div>
                                    <p className="text-20 AkagiPro-book mb-0">{t("CUSTOMER EXPERIENCE MANAGER")}</p>
                                    <h2 className="text-30 AkagiPro-Black  mb-0">{t("Lorem Ipsum")}</h2>
                                    <p className="text-20 AkagiPro-Black text-treed mb-0 " >{t("loremipsum@gmail.com")}</p>
                                </div>
                            </div>
                        </div>
                        <div className='relative'><div className='max-[767px]:absolute bottom-0 overflow-hidden'><img className='w-full img_animation' loading="lazy" src={Icons.contact1} /></div></div>
                    </div>
                </div>
            </section>
            <section id='1' className=' bg-skyYellow max-[767px]:py-5'>
                <div className='text-48 text-center pt-4 Alfarn'>{t("Join our team")}</div>
                <div className=" grid md:grid-cols-3 max-[767px]:grid-cols-1 gap-10 con container" >
                    {Housekeepings.map((Items,index) => (
                        <div key={index.toString()} className="bg-[#fdf6ee] md:my-10 relative" style={sectionimg}
                        >
                            <div className='bg-black h-[2px] w-[50px] -ml-2 mt-4'></div>
                            <div className='px-4 pt-4 pb-24'>
                                <div className='text-30  Alfarn text-[#905b17] text-left'>{t(Items.name)}</div>
                                <div > <button onClick={handleLinkClick} className=' an_button absolute left-6 bottom-6  bg-lightyellow text-20  mt-8  py-2 p_button uppercase AkagiPro-Black text-white' >{t("contact Us")}</button></div>
                            </div>
                        </div>
                    ))}
                </div>
            </section >
            <Link to={currentUrl}>
                <div className=" bg-lightyellow button_yellow text-white  md:py-12 max-[767px]:py-5 md:mb-10 max-[767px]:mb-5" style={sectionimgs}>
                    <div className="container">
                        <div className="text-48 text-center  Alfarn">{t("PLAN YOUR GETAWAY NOW")}</div>
                    </div>
                </div></Link>
            <Bookfooter />
        </div >
    )
}
export default ContactUs;