import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { setLoadingFalse,setLoadingTrue } from "../../Config/Actions/loadingActions";
import { useDispatch } from "react-redux";
export default function Corporateslider({ rabbitsliderd }) {
    const dispatch = useDispatch();
    const [slidesToShow, setSlidesToShow] = useState(2);
    const handleLinkClick = () => {
        dispatch(setLoadingTrue());
        setTimeout(() => {
            dispatch(setLoadingFalse());
        }, 1000);
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, -100);
    };

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 768) {
                setSlidesToShow(3);
            } else if (window.innerWidth >= 768 && window.innerWidth < 1280) {
                setSlidesToShow(2);
            } else {
                setSlidesToShow(3);
            }
        }

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    var settings = {
        dots: false,
        infinite: true,
        speed: 3000,
        slidesToScroll: 1,
        slidesToShow: slidesToShow,
        autoplay: true,
        autoplaySpeed: 100,
    };
    const { t } = useTranslation();
    return (
        <Slider style={{ }} {...settings} >

            {rabbitsliderd.map((item, index) => (

                <div key={index.toString()} className={`${item.className} relative md:h-full max-[767px]:mb-3 `}>
                    <Link className="md:h-full block" to={item.link} onClick={handleLinkClick} >
                        <div className="flex justify-center items-top md:p-20 max-[767px]:p-10 md:h-full ">
                            <div className="absolute top-0 left-0 w-full h-full object-cover animation_img">
                                <img loading="lazy" src={item.img} className="absolute top-0 left-0 w-full h-full object-cover Canoe_animation" />
                            </div>
                            <div className="relative text-white md:max-w-[506px] max-w-[250px]">
                                <h3 className="AkagiPro-Black text-20 " style={{ lineHeight: "25px" }}>{t(item.name)}</h3>
                                <h3 className="text-48 AkagiPro-Black mt-2 mb-2 capitalize" style={{ lineHeight: "47px" }}>{t(item.titlename)} <p className="mb-0 text-20 " style={{ textTransform: "uppercase" }}>{t(item.names)}</p></h3>

                                <h3 className="text-20 AkagiPro-Black " style={{ lineHeight: "22px" }}>{t(item.title)}</h3>

                            </div>
                        </div>
                    </Link>
                </div>

            ))}

        </Slider>
    );
}
