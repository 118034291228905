export const BaseApiUrl = "https://api.brevo.com/v3/smtp/email"
export const ApiKey = "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"
export const SenderName = "Fiddler Lake Resorts"
export const SenderEmail = "no-reply@fiddlerlakeresort.com"
export const ToName = "Jeffery Khoury"
export const ToEmail = "reservation@fiddlerlakeresort.com"
export const BaseUrl = "https://www.fiddlerlakeresort.com/"
export const SubscribeUrl = "api/subscribe"
export const PopUpNewSScribe = "api/news-subscribe"
export const BaseNewsLetterUrl = BaseUrl + SubscribeUrl
export const BasePopUpNewsLetterUrl = BaseUrl + PopUpNewSScribe
export const TranslateApi = "https://translation.googleapis.com/language/translate/v2"
export const GoogleTranslateApiKey = "AIzaSyBNG3TmD-qIBpFrx2tlVAL-Yt25fReIxv0"
